import React, { useEffect } from 'react';

const BackgroundCRT = () => {
    const videos = [
        '/assets/videos/WEBSITEBG_PSC_TP_tvai_MP4.mp4',
    ];

    useEffect(() => {
        const videoElement = document.getElementById('background-videoCRT');

        // Function to play video at the current index
        const playVideo = () => {
            const videoSrc = videos[0]; // Use the first (and only) video
            if (videoElement) {
                videoElement.src = videoSrc; // Set video source
                videoElement.load(); // Load the video
                videoElement.oncanplaythrough = () => {
                    videoElement.play();
                    videoElement.oncanplaythrough = null; // Remove the event listener
                };
            }
        };

        // Play the video
        playVideo();

        return () => {
            if (videoElement) {
                // Clean up event listeners when component unmounts
                videoElement.oncanplaythrough = null;
                videoElement.pause();
                videoElement.src = '';
            }
        };
    }, [videos]);

    return (
        <div style={{
            position: 'fixed',
            top: 0,
            left: 0,
            zIndex: -1,
            width: '100%',
            height: '100%',
            backgroundColor: 'black',
        }}>
            <video
                id="background-videoCRT"
                style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    transition: 'opacity 1s ease-out',
                }}
                playsInline
                muted
                loop // Enable video looping
            />
        </div>
    );
};

export default BackgroundCRT;
