import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './css_styling/home_button.css';
import HousevectorR from './ui-assets/HouseVector-R.png';
import HousevectorW from './ui-assets/HouseVector-W.png';

const HomeButton = ({ style }) => {
    const [isHovered, setIsHovered] = useState(false);
    const navigate = useNavigate(); // Hook to get the navigation function


    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const handleButtonClick = () => {
        // Add your click logic here
        //console.log("Button Clicked");
        // Navigate to the home page
        navigate('/');
    };


    return (
        <div
            className={`HomeButtonContainer ${isHovered ? 'hovered' : ''}`}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={handleButtonClick}
            style={{
                ...style,
                position: 'fixed',
                left: '5%',
                transform: 'scale(0.6)',
                transformOrigin: 'top left'
            }}
        >
            {isHovered ? (
                // Render extended component on hover
                <div className="HomebuttonHover" style={{ width: 76, height: 76, position: 'relative' }}>
                    <div className="Lastlayer2" style={{ width: 76, height: 76, left: 0, top: 0, position: 'absolute', background: 'rgba(255, 0, 0, 0.8)', boxShadow: '0px 0px 10px 5px rgba(255, 0, 0, 0.25)', borderRadius: 23 }} />
                    <img src={HousevectorR} alt="HousevectorRed" className="HousevectorR" style={{ width: 58, height: 58, left: 10, top: 15, position: 'absolute' }} />
                    <div className="Frontlayer1" style={{ width: 76, height: 76, left: 0, top: 0, position: 'absolute', background: 'rgba(217, 217, 217, 0.14)', boxShadow: '2px 4px 4px rgba(255, 0, 0, 0.25) inset', borderRadius: 23 }} />
                    <div className="Title" style={{ position: 'absolute', top: '45%', left: '160%', transform: 'translate(-50%, -50%)', fontWeight: 'bold', fontFamily: 'sans-serif', fontSize: 25 }}>
                        HOME
                    </div>



                </div>
            ) : (
                // Render default component
                <div className="Homebutton" style={{ width: 76, height: 76, position: 'relative' }}>
                    <div className="Lastlayer1" style={{ width: 76, height: 76, left: 0, top: 0, position: 'absolute', background: 'rgba(217, 217, 217, 0.12)', borderRadius: 23, backdropFilter: 'blur(10px)' }} />
                    <img src={HousevectorW} alt="HousevectorWhite" className="HousevectorW" style={{ width: 38, height: 38, left: 19, top: 19, position: 'absolute' }} />
                </div>
            )}
        </div>
    );
};

export default HomeButton;
