import React from 'react';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="content">
        <div className="company-name">TROJOHN LLC</div>
        <div className="copyright">
          &copy; {new Date().getFullYear()} All rights reserved
        </div>
      </div>
    </footer>
  );
};

export default Footer;