import React, { useState } from 'react';
import BMenuButtonMorph from './sub-components/BMenuButton';
import HomeButton from './sub-components/home_button';
import BackgroundCRT from './background-crt';
import Footer from './footer';
import AboutAnchor from './sub-components/anchorButton';

// Import videos for artists
import SubtronicsVideo1 from './sub-components/videoShowReel/Subtronics/SUBREEL1.mp4';
import SubtronicsVideo2 from './sub-components/videoShowReel/Subtronics/SUBREEL2.mp4';
import SubtronicsVideo3 from './sub-components/videoShowReel/Subtronics/SUBREEL3.mp4';
import SubtronicsVideo4 from './sub-components/videoShowReel/Subtronics/SubREEL4.mov';
import SubtronicsVideo5 from './sub-components/videoShowReel/Subtronics/Subreel5.mov';
import SubtronicsVideo6 from './sub-components/videoShowReel/Subtronics/SUBREEL6.mov';
import SubtronicsVideo7 from './sub-components/videoShowReel/Subtronics/SUBREEL7.mov';

import ExcisionV1 from './sub-components/videoShowReel/Excision/X_WOOLI_LL24_INTRO_TOPAZZ.mov';

// Video Component for fade transitions
const VideoPlayer = ({ videoSrc, onTransitionEnd }) => {
    const [fade, setFade] = useState('fade-in');

    // Trigger fade-in effect when the video source changes
    React.useEffect(() => {
        setFade('fade-in');
    }, [videoSrc]);

    // Handle fade-out effect before switching videos
    const handleFadeOut = () => {
        setFade('fade-out');
        setTimeout(() => {
            if (onTransitionEnd) {
                onTransitionEnd(); // Notify the parent to switch the video after fade-out completes
            }
        }, 500); // Match this duration to the CSS fade-out transition time
    };

    return (
        <div className={`video-container ${fade}`}>
            <video 
                width="100%" 
                height="100%" 
                autoPlay 
                loop 
                key={videoSrc}
                onEnded={handleFadeOut}
                onClick={handleFadeOut}
            >
                <source src={videoSrc} type="video/mp4" />
            </video>
        </div>
    );
};

// Artist Configuration
const artistConfig = {
    Subtronics: {
        videos: [SubtronicsVideo1, SubtronicsVideo2, SubtronicsVideo3, SubtronicsVideo4, SubtronicsVideo5, SubtronicsVideo6, SubtronicsVideo7],
        info: 'Antifractals',
    },
    Excision: {
        videos: [ExcisionV1],
        info: 'Excision X Wooli: Lost Lands Intro',
    },
    // Add new artists here as you scale
};

// Main Project Showcase Component
const ProjectShowcase = ({videoSrc, onTransitionEnd}) => { 
    const [renderBackground, setRenderBackground] = useState(true);
    const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
    const [currentArtist, setCurrentArtist] = useState('');
    const [activeButton, setActiveButton] = useState('');
    const [showInfoCard, setShowInfoCard] = useState(false);

    const [fade, setFade] = useState('fade-in');

    // Trigger fade-in effect when the video source changes
    React.useEffect(() => {
        setFade('fade-in');
    }, [videoSrc]);

    // Handle fade-out effect before switching videos
    const handleFadeOut = () => {
        setFade('fade-out');
        setTimeout(() => {
            if (onTransitionEnd) {
                onTransitionEnd(); // Notify the parent to switch the video after fade-out completes
            }
        }, 500); // Match this duration to the CSS fade-out transition time
    };

    // Function to render navigation dots based on the number of videos
    const renderNavDots = (videos) => videos.map((_, index) => (
        <span
            key={index}
            style={{
                height: currentVideoIndex === index ? '15px' : '10px',
                width: currentVideoIndex === index ? '15px' : '10px',
                backdropFilter: 'blur(20px)',
                borderRadius: '50%',
                display: 'inline-block',
                margin: '0 5px',
                transition: 'width 0.3s ease-in-out',
                filter: 'invert(1)',
                cursor: 'pointer',
            }}
            onClick={() => setCurrentVideoIndex(index)}
        />
    ));

    const handleButtonClick = (artistName) => {
        if (artistName === activeButton) {
            resetVideoState();
        } else {
            setActiveButton(artistName);
            setCurrentArtist(artistName);
            setRenderBackground(false);
            setCurrentVideoIndex(0); // Start with the first video of the selected artist
        }
    };

    const resetVideoState = () => {
        setRenderBackground(true);
        setActiveButton('');
        setCurrentArtist('');
    };

    const handleNextVideo = () => {
        const artistVideos = artistConfig[currentArtist]?.videos;
        if (artistVideos) {
            const nextIndex = (currentVideoIndex + 1) % artistVideos.length;
            setCurrentVideoIndex(nextIndex);
            handleFadeOut();
        }
    };

    const handlePreviousVideo = () => {
        const artistVideos = artistConfig[currentArtist]?.videos;
        if (artistVideos) {
            const prevIndex = (currentVideoIndex - 1 + artistVideos.length) % artistVideos.length;
            setCurrentVideoIndex(prevIndex);
        }
    };

    return (
        <div style={{ backgroundColor: 'transparent', minHeight: '100vh' }}>
            <HomeButton onClick={resetVideoState} />
            {Object.keys(artistConfig).map((artistName, index) => (
                <BMenuButtonMorph 
                    artistName={artistName}
                    style={{ top: `${15 + index * 15}%`, zIndex: 10 }} 
                    onClick={() => handleButtonClick(artistName)} 
                    key={`button-${artistName}`} 
                    activeButton={activeButton}
                    isInteractive={!activeButton || activeButton === artistName}
                />
            ))}
            <AboutAnchor />
            <Footer />

            {renderBackground && <BackgroundCRT />}

            {!renderBackground && currentArtist && (
                <div style={{ background: '#020202', width: '100%', height: '100%', position: 'fixed', top: 0, left: 0, zIndex: 5 }}>
                    <VideoPlayer 
                        videoSrc={artistConfig[currentArtist].videos[currentVideoIndex]} 
                        onTransitionEnd={handleNextVideo}
                    />

                    <div style={{
                        background: 'rgba(0, 0, 0, 0.582)',
                        borderRadius: '30px',
                        position: 'absolute',
                        top: '20px',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        zIndex: 10,
                        backdropFilter: 'blur(15px)',
                        padding: '10px',
                    }}>
                        <button 
                            onClick={() => setShowInfoCard(!showInfoCard)} 
                            style={{ fontSize: '24px', fontFamily: 'Inter, sans-serif', cursor: 'pointer' }}
                        >
                            i
                        </button>
                    </div>

                    {showInfoCard && (
                        <div style={{
                            position: 'absolute',
                            top: '100px',
                            left: '50%',
                            transform: 'translateX(-50%)',
                            background: 'rgba(0, 0, 0, 0.5)',
                            borderRadius: '30px',
                            padding: '40px',
                            zIndex: 10,
                            color: 'white',
                            maxWidth: '500px',
                            textAlign: 'center',
                            backdropFilter: 'blur(20px)',
                        }}>
                            <p style={{ fontSize: '20px', fontFamily: 'Inter, sans-serif' }}>
                                {artistConfig[currentArtist]?.info}
                            </p>
                        </div>
                    )}

                    <div style={{
                        position: 'absolute',
                        bottom: '90px',
                        left: '60%',
                        transform: 'translateX(-50%)',
                        zIndex: 10,
                    }}>
                        {renderNavDots(artistConfig[currentArtist]?.videos)}
                    </div>

                    <div style={{
                        position: 'absolute',
                        background: 'rgba(0, 0, 0, 0.582)',
                        left: '50%',
                        bottom: '80px',
                        transform: 'translateX(-50%)',
                        zIndex: 10,
                        backdropFilter: 'blur(15px)',
                        padding: '10px',
                        borderRadius: '30px',
                    }}>
                        <button 
                            onClick={handlePreviousVideo} 
                            style={{ marginRight: '5px', cursor: 'pointer' }}
                        >
                            Previous
                        </button>
                        <span>|</span>
                        <button 
                            onClick={handleNextVideo} 
                            style={{ marginLeft: '5px', cursor: 'pointer' }}
                        >
                            Next
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ProjectShowcase;
