import React, { useState } from 'react';
import './css_styling/about_anchor.css';

const AboutAnchor = () => {
    const [isOpen, setIsOpen] = useState(true); // Start in the "open" state

    const sendEmail = () => {
        const subject = encodeURIComponent('Inquiry');
        const body = encodeURIComponent('Hello, curious about your animation services.');

        // Use a mailto link to open the default email client
        window.location.href = `mailto:connect@trojohn.com?subject=${subject}&body=${body}`;
    };

    const toggleDrawer = () => {
        setIsOpen(!isOpen); // Toggle between open and closed
    };

    return (
        <div>
        </div>
    );
};

export default AboutAnchor;
