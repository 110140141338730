import React, { useEffect, useState } from 'react';

const FancyCard = ({ isOpen, onClose }) => {
    const [isVisible, setIsVisible] = useState(false); // State to control fade-in/out

    // Video sources
    const videos = [
        '/assets/videos/BIOWHEEL_TPPFX_tvai_mp4.mp4',
    ];

    useEffect(() => {
        const videoElement = document.getElementById('bio-background-video');

        // Function to play the video
        const playVideo = () => {
            const videoSrc = videos[0];
            if (videoElement) {
                videoElement.src = videoSrc;
                videoElement.load();
                videoElement.oncanplay = () => {
                    videoElement.play();
                };
            }
        };

        // Event handler when the video ends to instantly close the component
        const handleVideoEnd = () => {
            if (onClose) {
                setIsVisible(false); // Fade out the logo
                setTimeout(() => {
                    onClose(); // Close the component after fade out
                }, 500); // Wait for the fade-out transition to complete
            }
        };

        // Play the video and set the event listener for ending
        if (isOpen && videoElement) {
            playVideo();
            videoElement.onended = handleVideoEnd; // Close instantly on video end

            // Fade in the logo when the component opens
            setTimeout(() => {
                setIsVisible(true); // Fade in
            }, 100); // Small delay to ensure smooth transition
        } else {
            // Reset visibility when component is closed
            setIsVisible(false);
        }

        return () => {
            if (videoElement) {
                videoElement.oncanplay = null;
                videoElement.onended = null;
                videoElement.pause(); // Pause and cleanup the video
                videoElement.src = ''; // Clear the source
            }
        };
    }, [isOpen, onClose, videos]);

    if (!isOpen) return null; // Do not render if the FancyCard is not open

    return (
        <div className="bio-page">
            {/* Background Video */}
            <div style={{
                position: 'fixed',
                top: 0,
                left: 0,
                zIndex: -1,
                width: '100%',
                height: '100%',
                backgroundColor: 'black',
            }}>
                <video
                    id="bio-background-video"
                    style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        transition: 'opacity 1s ease-out',
                    }}
                    playsInline
                    muted
                />
            </div>

            {/* Bio Content Overlay */}
            <div className="bio-content">
                <img
                    src="/assets/images/trojohn-logo_trans.png"
                    alt="Profile"
                    className="profile-pic"
                    style={{
                        opacity: isVisible ? 1 : 0, // Control fade-in/out via opacity
                        transition: 'opacity 0.5s ease-in-out', // Smooth transition effect
                    }}
                />
                <button className="close-button" onClick={onClose}>
                    Close
                </button>
            </div>
        </div>
    );
};

export default FancyCard;
