import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import FancyCard from './bio'; // This is your bio component
import Menu from './menu';
import Footer from './footer';
import Background from './background'; // Your existing Background component
import Lottie from 'react-lottie';
import animationData from './lottie-files/circle-animation.json'; // Adjust the path accordingly
import AboutAnchor from './sub-components/anchorButton';

const Home = () => {
    const [hovered, setHovered] = useState(false);
    const [showBio, setShowBio] = useState(false);
    const [isBioActive, setIsBioActive] = useState(false); // New state to track if bio is active

    useEffect(() => {
        document.querySelector('.home-container').classList.add('loaded');
    }, []);

    const openBio = () => {
        setIsBioActive(true); // Signal to activate the bio background
        setShowBio(true);
    };

    const closeBioAfterAnimation = () => {
        setTimeout(() => {
            setShowBio(false);
            setIsBioActive(false); // Reset the bio state when closing
        }, 500); // Time matches the duration of the closeEffect animation
    };

    const closeBio = () => {
        closeBioAfterAnimation();
    };

    const handleBioClick = () => {
        if (showBio) {
            closeBioAfterAnimation();
        } else {
            openBio();
        }
    };

    const lottieOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };

    return (
        <div className="home-container">
            <Menu onBioClick={handleBioClick} />
            {showBio && (
                <FancyCard
                    isOpen={showBio}
                    onClose={handleBioClick}
                />
            )}
            <AboutAnchor />
            <Footer />
            <Background isActive={isBioActive} /> {/* Pass the active state to Background */}
        </div>
    );
};

export default Home;
