// index.js or App.js
import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/home';
import Loading from './components/loading';
import ProjectShowcase from './components/projectShowcase'

const App = () => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            await new Promise((resolve) => setTimeout(resolve, 2000));
            setIsLoading(false);
        };
        fetchData();
    }, []);

    return (
        <Router>
            <Routes>
                <Route path="/" element={isLoading ? <Loading setIsLoading={setIsLoading} /> : <Home />} />
                <Route path="/showcase" element={<ProjectShowcase />} />
            </Routes>
        </Router>
    );
};


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
