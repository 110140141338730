import React, { useState } from 'react';
import './css_styling/BMenuButton.css';
import VectorImage from './ui-assets/Vector.png';
import VectorImage2 from './ui-assets/Vector2.png';

const BMenuButtonMorph = ({ artistName, style, onClick, activeButton, isInteractive }) => {
    const [isHovered, setIsHovered] = useState(false);
    const [isExtended, setIsExtended] = useState(false);
    const isActive = artistName === activeButton;
    const interactiveClass = isInteractive ? 'interactive' : 'non-interactive';

    const handleMouseEnter = () => {
        if (!isInteractive) return;
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        if (!isInteractive) return;
        setIsHovered(false);
    };

    const handleButtonClick = () => {
        if (!isInteractive) return;
        setIsExtended((prevIsExtended) => !prevIsExtended);
        onClick(artistName);
    };

    const getScale = () => {
        if (isActive || isExtended) {
            return 0.7; // Active or extended scale
        }
        return 0.5; // Default scale
    };

    return (
        <div
            className={`BMenuButtonMorphContainer ${interactiveClass} ${isHovered || isExtended ? 'hovered' : ''} ${isActive ? 'active' : ''}`}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={handleButtonClick}
            style={{
                ...style,
                position: 'fixed',
                left: '5%',
                transform: `scale(${getScale()})`,
                transformOrigin: 'top left'
            }}
        >
            {isExtended ? (
                <div className="BMenubuttonHoverExtContent">
                <div className="Rectangle2" style={{ width: 489, height: 123, left: 0, top: 0, position: 'absolute', background: 'rgba(101.02, 0, 0, 0.62)', borderRadius: 57, backdropFilter: 'blur(10px)' }} />
                <div className="Ellipse1" style={{ width: 100, height: 100, left: 16, top: 11, position: 'absolute', background: '#FF0000', borderRadius: 9999 }} />
                <div className="Rectangle1" style={{ width: 489, height: 123, left: 0, top: 0, position: 'absolute', background: 'rgba(217, 217, 217, 0.12)', borderRadius: 57, backdropFilter: 'blur(10px)', boxShadow: '0px 0px 20px 5px rgba(255, 0, 0, 0.25)' }} />
                <img src={VectorImage2} alt="Vector2" className="Vector2" style={{ width: 75, height: 75, left: 30, top: 30, position: 'absolute' }} />
                <div className="ArtistName" style={{
                    position: 'absolute',
                    transform: 'translateX(-50%)', // Offset the artist name to the left by half its width for true centering
                    zIndex: 15,
                    fontFamily: 'sans-serif',
                    whiteSpace: 'nowrap', // Keep the artist's name on a single line
                }}>
                    {artistName}
                </div>
             </div>
            ) : (
                <div className="BMenubuttonExtended" style={{ width: 133, height: 123, position: 'relative' }}>
                    <div className="Ellipse1" style={{ width: 75, height: 75, left: 30, top: 25, position: 'absolute', background: '#A60202', borderRadius: 9999 }} />
                    <div className="Rectangle1" style={{ width: 489, height: 123, left: 0, top: 0, position: 'absolute', background: 'rgba(217, 217, 217, 0.12)', borderRadius: 57, backdropFilter: 'blur(10px)' }} />
                    <img src={VectorImage} alt="Vector" className="Vector" style={{ width: 75, height: 75, left: 30, top: 30, position: 'absolute' }} />
                    <div className="ArtistName" style={{
                        position: 'absolute',
                        transform: 'translateX(-50%)', // Offset the artist name to the left by half its width for true centering
                        zIndex: 15,
                        fontFamily: 'sans-serif',
                        whiteSpace: 'nowrap', // Keep the artist's name on a single line
                    }}>
                        {artistName}
                    </div>
                </div>
            )}
        </div>
    );
};

export default BMenuButtonMorph;
