// Loading.jsx
import React, { useState, useEffect } from 'react';
import Lottie from 'react-lottie';
import animationData from './lottie-files/circle-animation.json'; // Adjust the path accordingly

const Loading = () => {
    const [progress, setProgress] = useState(0);

    const lottieOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };

    // Calculate the dynamic opacity based on the progress
    const dynamicOpacity = progress / 35;

    useEffect(() => {
        const interval = setInterval(() => {
            setProgress((prevProgress) => (prevProgress < 100 ? prevProgress + 1 : prevProgress));
        }, 50);

        return () => clearInterval(interval);
    }, []);

    return (
        <div className="loading-container">
            <div className="loading-overlay">
                <p>{progress}%</p>
            </div>
            <div className="lottie-loading1" style={{ opacity: dynamicOpacity }}>
                <Lottie options={lottieOptions} height={70} width={70} />
            </div>
        </div>
    );
};

export default Loading;
