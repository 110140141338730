import React, { useEffect, useState } from 'react';

const Background = ({ isActive }) => {
    const [currentVideoIndex, setCurrentVideoIndex] = useState(0);

    // Video sources (you can add more videos here)
    const videos = [
        '/assets/videos/WEBSITEBG_1_TP4_MP4.mp4',
        // Additional videos can be added here
    ];

    useEffect(() => {
        const videoElement = document.getElementById('background-video');

        // Function to play video at the current index
        const playVideoAtIndex = (index) => {
            const videoSrc = videos[index];
            if (videoElement) {
                videoElement.src = videoSrc;
                videoElement.load();
                videoElement.oncanplay = () => {
                    videoElement.play();
                };
            }
        };

        // Play the current video index on load
        playVideoAtIndex(currentVideoIndex);

        // Set the event listener for the video ending to automatically restart the same video (loop)
        if (videoElement) {
            videoElement.loop = true; // Set the video to loop
        }

        return () => {
            if (videoElement) {
                // Clean up event listeners when component unmounts
                videoElement.oncanplay = null;
                videoElement.pause(); // Pause the video
                videoElement.src = ''; // Clear the source
            }
        };
    }, [currentVideoIndex, videos]);

    // If the bio is active, do not render the background
    if (isActive) return null;

    return (
        <div style={{
            position: 'fixed',
            top: 0,
            left: 0,
            zIndex: -1,
            width: '100%',
            height: '100%',
            backgroundColor: 'black',
        }}>
            <video
                id="background-video"
                style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    transition: 'opacity 1s ease-out',
                }}
                playsInline
                muted
            />
        </div>
    );
};

export default Background;
